import { styled } from '@mui/material';
import { ContentWrapper } from '../../../styles/shared';

export const StyledContentWrapper = styled(ContentWrapper)(({ theme }) => ({
  marginBottom: theme.spacings.unit(16),
  [theme.breakpointsMediaQuery.down('xl')]: {
    padding: '0 env(safe-area-inset-right) 0 env(safe-area-inset-left)',
  },
  [theme.breakpointsMediaQuery.up('xl')]: {
    padding: `0 ${theme.spacings.unit(26)}`,
  },
}));
