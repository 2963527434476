import { styled } from '@mui/material';
import {
  ArrowCircleLeftIcon,
  ArrowCircleRightIcon,
  CircleIcon,
} from '../../../assets';

export const Pagination = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: theme.spacings.unit(8),
  left: theme.spacings.unit(21),
  zIndex: 1,
}));

export const StyledArrowCircleLeftIcon = styled(ArrowCircleLeftIcon)<any>(
  ({ theme, disabled }) => ({
    color: disabled
      ? theme.colors.secondaryDisabledNextPrevButton
      : theme.colors.secondaryActiveNextPrevButton,
    cursor: disabled ? '' : 'pointer',
  })
);

export const StyledArrowCircleRightIcon = styled(ArrowCircleRightIcon)<any>(
  ({ theme, disabled }) => ({
    color: disabled
      ? theme.colors.secondaryDisabledNextPrevButton
      : theme.colors.secondaryActiveNextPrevButton,
    cursor: disabled ? '' : 'pointer',
  })
);

export const CarouselWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  marginTop: '5rem',
  height: theme.spacings.unit(103.5),
  [theme.breakpointsMediaQuery.up('md')]: {
    height: theme.spacings.unit(150),
  },
  '& li': {
    width: '100% !important',
  },
}));

export const NavigationDots = styled('nav', {
  shouldForwardProp: (prop) => prop !== 'dotsAlignment',
})(({ theme, dotsAlignment }) => ({
  position: 'absolute',
  bottom: theme.spacings.unit(8),
  left: theme.spacings.unit(21),
  textAlign: dotsAlignment,
}));

export const StyledCircleIcon = styled(CircleIcon, {
  shouldForwardProp: (prop) => prop !== 'active',
})(({ theme, active }) => ({
  color: active ? theme.colors.activeDot : theme.colors.dot,
  cursor: 'pointer',
  width: theme.spacings.unit(3),
  height: theme.spacings.unit(3),
  marginRight: theme.spacings.unit(2),
  '&:hover': {
    color: active ? theme.colors.activeDot : theme.colors.dotHover,
  },
}));
