import React from 'react';
import CarouselBio from '../../../components/carousel/carousel-bio/CarouselBio';
import { StyledContentWrapper } from './SliderBio.style';
import { SliderConfigs } from '../../../models/domain/interfaces';
import { defaultSliderConfigs } from 'packages/corporate-ui/src/utils/carousels';

interface SliderBioProps {
  cardBio: any;
  images: any;
  size: any;
  sliderConfigs?: SliderConfigs;
}

const SliderBio: React.FC<any> = ({
  data,
  isPrerenderRequest,
}: {
  data: SliderBioProps;
  isPrerenderRequest: boolean;
}) => {
  return (
    <StyledContentWrapper>
      <CarouselBio
        cards={data?.cardBio ?? []}
        sliderConfigs={data?.sliderConfigs ?? defaultSliderConfigs}
        isPrerenderRequest={isPrerenderRequest}
      />
    </StyledContentWrapper>
  );
};

export default SliderBio;
