import React, { useState } from 'react';
import AliceCarousel, { Responsive } from 'react-alice-carousel';
import {
  CarouselWrapper,
  NavigationDots,
  Pagination,
  StyledArrowCircleLeftIcon,
  StyledArrowCircleRightIcon,
  StyledCircleIcon,
} from './CarouselBio.style';
import CardBio from '../../cards/card-bio/CardBio';
import CardBioMobile from '../../cards/card-bio-mobile/CardBioMobile';
import { HideOnDesktop, HideOnMobile } from '../../../styles/shared';
import { SliderConfigs } from '../../../models/domain/interfaces';
import { defaultSliderConfigs } from '../../../utils/carousels';

interface Card {
  category?: string;
  description?: string;
  button?: any;
  image?: any;
  color?: string;
  rightBackground?: string;
  rightColor?: string;
  rightImage?: any;
}

interface BioCarouselProps {
  cards: Card[];
  isPrerenderRequest: boolean;
  itemPerPage?: Responsive;
  sliderConfigs?: SliderConfigs;
}

const BioCarousel: React.FC<BioCarouselProps> = ({
  sliderConfigs = defaultSliderConfigs,
  ...props
}: BioCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  const slidePrev = () => setActiveIndex(activeIndex - 1);
  const slideNext = () => setActiveIndex(activeIndex + 1);
  const syncActiveIndex = ({ item }: any) => setActiveIndex(item);

  const [carousel, setCarousel] = useState<any>(null);
  const thumbItem = (item: any, i: number) => (
    <StyledCircleIcon
      active={i === activeIndex}
      key={i}
      onClick={() => carousel?.slideTo(i)}
    />
  );

  const cardsMapper = (cards: Card[]) =>
    cards &&
    Array.isArray(cards) &&
    cards?.map((card, i) => ({
      key: i,
      image: card.image,
      category: card.category,
      description: card.description,
      button: card.button,
      color: card.color,
      rightBackground: card.rightBackground,
      rightColor: card.rightColor,
      rightImage: card.rightImage,
      isPrerenderRequest: props.isPrerenderRequest,
    }));

  const mappedCards = cardsMapper(props.cards);
  const carouselItems = Object.values(mappedCards)?.map((items) => (
    <CardBio data={items} isPrerenderRequest={props.isPrerenderRequest} />
  ));

  return (
    <CarouselWrapper>
      <HideOnMobile>
        {carouselItems.length > 1 && (
          <Pagination>
            <StyledArrowCircleLeftIcon
              disabled={activeIndex === 0}
              onClick={activeIndex === 0 ? () => {} : slidePrev}
            />
            <StyledArrowCircleRightIcon
              disabled={activeIndex === carouselItems.length - 1}
              onClick={
                activeIndex === carouselItems.length - 1 ? () => {} : slideNext
              }
            />
          </Pagination>
        )}
        <AliceCarousel
          responsive={props.itemPerPage}
          mouseTracking={true}
          disableButtonsControls
          disableDotsControls
          activeIndex={activeIndex}
          onSlideChanged={syncActiveIndex}
          items={carouselItems}
          ref={(el: AliceCarousel) => setCarousel(el)}
          controlsStrategy={'responsive'}
          autoPlay={sliderConfigs.autoPlay}
          autoPlayInterval={sliderConfigs.autoPlayInterval}
          animationDuration={sliderConfigs.animationDuration}
          animationType={sliderConfigs.animationType}
          infinite={sliderConfigs.infinite}
          autoPlayControls={false}
          autoPlayStrategy="none"
        />
        {carouselItems.length > 1 && (
          <NavigationDots dotsAlignment={'right'}>
            {carouselItems?.map(thumbItem)}
          </NavigationDots>
        )}
      </HideOnMobile>
      <HideOnDesktop>
        <CardBioMobile data={(mappedCards as any)[0]} />
      </HideOnDesktop>
    </CarouselWrapper>
  );
};

export default BioCarousel;
