import React from 'react';
import Markdown from 'markdown-to-jsx';
import Link from 'next/link';

import { hexToRgb, rgbDataURL } from 'corporate-utils';

import { getStrapiMedia } from '../../../utils/media';

import {
  BottomContainer,
  CardBox,
  CardImageStyled,
  CardRightOverImageStyled,
  CardTextStyled,
  Category,
  Container,
  CTA,
  OverlayStyled,
  RelativeContainer,
  StyledText,
  Title,
} from './CardBioMobile.style';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

interface CardProps {
  data: {
    category?: string;
    description?: string;
    button?: any;
    image?: any;
    color?: string;
    rightBackground?: any;
    rightColor?: string;
    rightImage?: any;
  };
  isPrerenderRequest: boolean;
}

const CardBioMobile: React.FC<any> = ({
  data,
  isPrerenderRequest,
}: CardProps) => {
  const {
    category,
    description,
    button,
    image,
    color,
    rightBackground,
    rightImage,
  } = data || {};

  return (
    <CardBox>
      <Container>
        <CardImageStyled color={color}>
          {image && image.data && image.data.attributes && (
            <ImageWithFallback
              layout="responsive"
              width={image?.data?.attributes?.width || '1920'}
              height={image?.data?.attributes?.height || '1920'}
              objectFit="cover"
              placeholder="blur"
              blurDataURL={rgbDataURL(
                hexToRgb('ffffff')?.[0],
                hexToRgb('ffffff')?.[1],
                hexToRgb('ffffff')?.[2]
              )}
              src={getStrapiMedia(image?.data?.attributes?.url ?? '')}
              alt={image?.data?.attributes?.alternativeText || ''}
              fallbackSrc={DEFAULT_IMAGE}
              isPrerenderRequest={isPrerenderRequest}
            />
          )}
        </CardImageStyled>
        {color ? <OverlayStyled overlay={color}></OverlayStyled> : <></>}
      </Container>
      <BottomContainer>
        <RelativeContainer>
          <CardTextStyled color={color}>
            <StyledText>
              {category && <Category>{category}</Category>}
              {description && (
                <Title>
                  <Markdown>{description}</Markdown>
                </Title>
              )}
              {button && button.text && button.url && (
                <Link href={button.url} passHref>
                  <CTA>{button.text}</CTA>
                </Link>
              )}
            </StyledText>
          </CardTextStyled>
          {rightBackground && rightImage && (
            <CardRightOverImageStyled>
              {rightImage && rightImage.data && rightImage.data.attributes && (
                <ImageWithFallback
                  layout="fill"
                  objectFit="contain"
                  placeholder="blur"
                  blurDataURL={rgbDataURL(
                    hexToRgb('ffffff')?.[0],
                    hexToRgb('ffffff')?.[1],
                    hexToRgb('ffffff')?.[2]
                  )}
                  src={getStrapiMedia(rightImage?.data?.attributes?.url ?? '')}
                  alt={rightImage?.data?.attributes?.alternativeText || ''}
                  fallbackSrc={DEFAULT_IMAGE}
                  isPrerenderRequest={isPrerenderRequest}
                />
              )}
            </CardRightOverImageStyled>
          )}
        </RelativeContainer>
      </BottomContainer>
    </CardBox>
  );
};

export default CardBioMobile;
