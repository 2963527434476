import React from 'react';
import Markdown from 'markdown-to-jsx';
import Link from 'next/link';
import {
  capitalizeOnlyFirstLetter,
  hexToRgb,
  rgbDataURL,
} from 'corporate-utils';

import { getStrapiMedia } from '../../../utils/media';

import {
  CTA,
  CardBox,
  CardImageStyled,
  RightContainer,
  CardTextStyled,
  Category,
  Container,
  OverlayStyled,
  StyledText,
  Title,
  CardRightBackgroundImageStyled,
  CardRightOverImageStyled,
} from './CardBio.style';
import { DEFAULT_IMAGE } from '../../../models/domain/const';
import ImageWithFallback from '../../image-with-fallback/ImageWithFallback';

interface CardProps {
  data: {
    category?: string;
    description?: string;
    button?: any;
    image?: any;
    color?: string;
    rightBackground?: any;
    rightColor?: string;
    rightImage?: any;
  };
  isPrerenderRequest: boolean;
}

const CardBio: React.FC<CardProps> = ({ data, isPrerenderRequest }) => {
  const {
    category,
    description,
    button,
    image,
    color,
    rightBackground,
    rightColor,
    rightImage,
  } = data || {};

  return (
    <CardBox>
      <CardTextStyled color={color}>
        <StyledText>
          {category && <Category>{category}</Category>}
          {description && (
            <Title>
              <Markdown>{description}</Markdown>
            </Title>
          )}
          {button && button.text && button.url && (
            <Link href={button.url} passHref>
              <CTA>{capitalizeOnlyFirstLetter(button.text)}</CTA>
            </Link>
          )}
        </StyledText>
      </CardTextStyled>
      <Container>
        <CardImageStyled color={color}>
          {image && image.data && image.data.attributes && (
            <ImageWithFallback
              layout="fill"
              objectFit="cover"
              placeholder="blur"
              blurDataURL={rgbDataURL(
                hexToRgb('ffffff')?.[0],
                hexToRgb('ffffff')?.[1],
                hexToRgb('ffffff')?.[2]
              )}
              src={getStrapiMedia(image?.data?.attributes?.url ?? '')}
              alt={image?.data?.attributes?.alternativeText || ''}
              fallbackSrc={DEFAULT_IMAGE}
              isPrerenderRequest={isPrerenderRequest}
            />
          )}
        </CardImageStyled>
        {color ? <OverlayStyled overlay={color}></OverlayStyled> : <></>}
      </Container>
      {rightBackground && rightImage && (
        <RightContainer>
          <CardRightBackgroundImageStyled>
            {rightBackground &&
              rightBackground.data &&
              rightImage.data.attributes && (
              <ImageWithFallback
                layout="fill"
                objectFit="cover"
                placeholder="blur"
                blurDataURL={rgbDataURL(
                  hexToRgb('ffffff')?.[0],
                  hexToRgb('ffffff')?.[1],
                  hexToRgb('ffffff')?.[2]
                )}
                src={getStrapiMedia(
                  rightBackground?.data?.attributes?.url ?? ''
                )}
                alt={rightImage?.data?.attributes?.alternativeText || ''}
                fallbackSrc={DEFAULT_IMAGE}
                isPrerenderRequest={isPrerenderRequest}
              />
            )}
          </CardRightBackgroundImageStyled>
          {rightColor ? (
            <OverlayStyled overlay={rightColor}></OverlayStyled>
          ) : (
            <></>
          )}
          <CardRightOverImageStyled>
            {rightImage && rightImage.data && rightImage.data.attributes && (
              <ImageWithFallback
                layout="fill"
                objectFit="contain"
                placeholder="blur"
                blurDataURL={rgbDataURL(
                  hexToRgb('ffffff')?.[0],
                  hexToRgb('ffffff')?.[1],
                  hexToRgb('ffffff')?.[2]
                )}
                src={getStrapiMedia(rightImage?.data?.attributes?.url ?? '')}
                alt={rightImage?.data?.attributes?.alternativeText || ''}
                fallbackSrc={DEFAULT_IMAGE}
                isPrerenderRequest={isPrerenderRequest}
              />
            )}
          </CardRightOverImageStyled>
        </RightContainer>
      )}
    </CardBox>
  );
};

export default CardBio;
