import { styled } from '@mui/material';
import { StyledLink } from '../../../styles/shared';

export const CardTextStyled = styled('div')(({ theme, color }) => ({
  background: color,
  minHeight: theme.spacings.unit(36.5),
}));

export const StyledText = styled('div')(({ theme, color }) => ({
  padding: `0 ${theme.spacings.unit(9)} ${theme.spacings.unit(4)}`,
}));

export const CardImageStyled = styled('div')(({ theme }) => ({
  display: 'block',
  flex: 2,
  overflow: 'hidden',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  zIndex: -3,

  [theme.breakpointsMediaQuery.up('md')]: {
    display: 'none',
  },
}));

export const CardRightImageStyled = styled(CardImageStyled, {
  shouldForwardProp: (prop) =>
    prop !== 'rightBackground' && prop !== 'rightColor',
})(({ theme }) => ({
  position: 'absolute',
  right: 0,
  bottom: '45%',
  flex: 0.3,
  background: 'none',
  padding: `0 ${theme.spacings.unit(7.25)} 0 0`,
  [theme.breakpointsMediaQuery.up('ssm')]: {
    bottom: '30%',
  },
  [theme.breakpointsMediaQuery.up('sm')]: {
    bottom: '27%',
  },
  [theme.breakpointsMediaQuery.up('md')]: {
    display: 'none',
  },
}));

export const CardBox = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(103.5),
  display: 'flex',
  flexDirection: 'column',
}));

export const Category = styled('h5')(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.colors.white,
  fontSize: theme.fonts.utility.size(6),
  paddingTop: theme.spacings.unit(3.6925),
  marginBottom: theme.spacings.unit(4),
  letterSpacing: theme.spacings.unit(0.075),
}));

export const Title = styled('h2')(({ theme }) => ({
  color: theme.colors.white,
  fontSize: theme.fonts.utility.size(10),
  margin: `${theme.spacings.unit(1)} 0`,
  fontWeight: 'normal',
  maxWidth: theme.spacings.unit(137.5),
}));

export const CTA = styled(StyledLink)(({ theme }) => ({
  marginTop: theme.spacings.unit(3.3),
  fontSize: theme.fonts.utility.size(6),
  color: theme.colors.white,
  '&:hover': {
    color: theme.colors.white,
  },
}));

export const Container = styled(
  'div',
  {}
)(() => ({
  position: 'relative',
  width: '100%',
  flex: 1,
}));

export const OverlayStyled = styled('div', {
  shouldForwardProp: (prop) => !['overlay'].includes(prop as string),
})(({ overlay }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  zIndex: -2,
  background: overlay ? `${overlay}50` : '',
}));

export const BottomContainer = styled(
  'div',
  {}
)(() => ({
  position: 'absolute',
  width: '100%',
  right: 0,
  background: 'none',
  zIndex: 1,
  bottom: 0,
}));

export const CardRightOverImageStyled = styled(CardImageStyled, {
  shouldForwardProp: (prop) => prop !== 'rightColor',
})(({ theme }) => ({
  position: 'absolute',
  background: 'none',
  height: theme.spacings.unit(19),
  aspectRatio: '1/1',
  transform: 'translateY(-50%)',
  right: 0,
  zIndex: 0,
  left: 'initial',
  margin: `0 ${theme.spacings.unit(7.25)}`,
}));

export const RelativeContainer = styled(
  'div',
  {}
)(() => ({
  position: 'relative',
}));
