import { styled } from '@mui/material';
import { StyledLink } from '../../../styles/shared';

export const CardTextStyled = styled('div')(({ theme, color }) => ({
  flex: 1,
  background: color,
  minHeight: theme.spacings.unit(90),
}));

export const StyledText = styled('div')(({ theme, color }) => ({
  padding: `${theme.spacings.unit(18)} ${theme.spacings.unit(104 / 4)}`,
}));

export const CardImageStyled = styled('div')(({ theme, color }) => ({
  display: 'none',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  zIndex: -3,

  [theme.breakpointsMediaQuery.up('md')]: {
    display: 'block',
    flex: 1,
    background: color ? `${color}80` : '',
  },
}));

export const CardRightBackgroundImageStyled = styled(CardImageStyled, {
  shouldForwardProp: (prop) => prop !== 'rightColor',
})(({ theme }) => ({
  position: 'relative',
  display: 'none',
  height: '100%',
  [theme.breakpointsMediaQuery.up('md')]: {
    display: 'flex',
    flex: 0.3,
  },
}));

export const CardBox = styled('div')(({ theme }) => ({
  height: theme.spacings.unit(150),
  display: 'flex',
  cursor: 'pointer',
}));

export const Category = styled('h3')(({ theme }) => ({
  textTransform: 'uppercase',
  color: theme.colors.white,
  marginBottom: '1rem',
}));

export const Title = styled('h3')(({ theme }) => ({
  color: theme.colors.white,
  fontSize: '2.5rem',
  margin: `${theme.spacings.unit(1)} 0`,
  fontWeight: 'normal',
  maxWidth: '550px',
}));

export const CTA = styled(StyledLink)(({ theme }) => ({
  marginTop: theme.spacings.unit(8),
  fontSize: theme.fonts.utility.size(9),
  color: theme.colors.white,
  '&:hover': {
    color: theme.colors.white,
  },
}));

export const Container = styled(
  'div',
  {}
)(() => ({
  position: 'relative',
  width: '100%',
  flex: 1,
}));

export const OverlayStyled = styled('div', {
  shouldForwardProp: (prop) => !['overlay'].includes(prop as string),
})(({ overlay }) => ({
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
  position: 'absolute',
  display: 'flex',
  zIndex: -2,
  background: overlay ? `${overlay}50` : '',
}));

export const RightContainer = styled(
  'div',
  {}
)(() => ({
  position: 'relative',
  width: '100%',
  flex: 0.3,
}));

export const CardRightOverImageStyled = styled(CardImageStyled, {
  shouldForwardProp: (prop) => prop !== 'rightColor',
})(({ theme }) => ({
  position: 'absolute',
  zIndex: '-1',
  display: 'none',
  [theme.breakpointsMediaQuery.up('md')]: {
    display: 'flex',
    flex: 0.3,
    margin: `0 ${theme.spacings.unit(4)}`,
  },
}));
